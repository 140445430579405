<template>
  <b-card>
    <base-info
      ref="baseinfo"
      :geturl="dataurl"
      :fields="fields"
      :baseroute="baseroute"
      :fields-time-line="fieldsTimeLine"
      :col_single="false"
      :isedit="model.status == 'created' && jobTitle.settings.includes('vehicle.change.edit')"
      :isdelete="model.status == 'created' && jobTitle.settings.includes('vehicle.change.delete')"
      :is-action="model.status == 'created'"
      @readData="showData"
    >

      <template
        v-if="userType == 'vendor'"
        slot="buttonaction"
      >
        <b-button
          v-if="jobTitle.settings.includes('vehicle.change.approve')"
          class="mr-1"
          size="sm"
          variant="info"
          type="button"
          @click.prevent="action('approve')"
        >
          <span>Setujui</span>
        </b-button>
        <b-button
          v-if="jobTitle.settings.includes('vehicle.change.reject')"
          class="mr-1"
          size="sm"
          variant="warning"
          type="button"
          @click.prevent="action('reject')"
        >
          <span>Batalkan</span>
        </b-button>
      </template>
    </base-info>


    <b-row>
      <b-col cols="12">
        <hr>
      </b-col>
      <b-col cols="12">
        <h3 class="font-weight-bolder">
          Daftar Kendaraan
        </h3>
        <br>
        
        <b-overlay
          :show="loading"
          variant="light"
          :opacity="0.5"
          rounded="sm"
        >

          <b-table
            striped
            responsive
            :items="items"
            :fields="fieldsTable"
          />

        </b-overlay>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>

import {BCard, BCol, BRow, BTable, BOverlay, BButton} from 'bootstrap-vue'
import BaseInfo from '@/views/base/BaseInfoV3.vue'
import VueApexCharts from 'vue-apexcharts'
import moment from 'moment'

export default {
  components: {
    VueApexCharts,
    BCard,
    BRow,
    BCol,
    BaseInfo,
    BTable, 
    BOverlay,
    BButton
  },

  data() {
    return {
      user:null,
      userType:null,
      dataurl:"/vehicle-change",
      baseroute:"vehicle-change",
      fields: [
        { key: 'number', label: 'Kode', bold:true},
        { key: 'spb.number', label: 'Nomor SPB', bold:true},
        { key: 'author.name', label: 'Pemberi Persetujuan' },
        { key: 'request_date', label: 'Tgl Permintaan' , type:'date'},
        // { key: 'realisation_date', label: 'Tgl Kebutuhan' , type:'date'},
        { key: 'note', label: 'Keterangan' },
        { key: 'status', label: 'Status', type:'spanKey', data:{created:{color:'bg-warning',value:'Pengajuan'}, approved:{color:'bg-primary',value:'Distujui'}, rejected:{color:'bg-danger',value:'Dibatalkan'}} },
        // { key: 'author.name', label: 'Pemesanan' },
        // { key: 'received_date', label: 'Tgl Persetujuan' , type:'date'},
        // { key: 'destination.name', label: 'Cabang Tujuan' },
      ],
      fieldsTable: [
        { key: 'vehicle.number', label: 'Nopol'},
        { key: 'vehicle.variant.model', label: 'Varian' },
        { key: 'vehicle.year', label: 'Tahun'},
        { key: 'vehicle.color', label: 'Warna'},
        { key: 'reason_note', label: 'Alasan'},
      ],
      fieldsTimeLine:[],
      items:[],
      loading:false,
      model:[],
      jobTitle: {
        settings: [],
      },
    }
  },
  created(){
    if (this.$store.state.profile.info) {
      this.userType =  this.$store.state.profile.info.contact_type.split('\\').pop().toLowerCase()
      this.user = this.$store.state.profile.info.contact
    }
    if (this.$route.meta.baseroute) this.baseroute = this.$route.meta.baseroute
  },
  mounted() {
      this.fetchData();
  },
  methods: {
    fetchData(){
        this.$http.get('auth/me').then( res => {
          for (let setting of res.data.contact.job_title.settings)
          {
            if (setting.approval && setting.module){
              this.jobTitle.settings.push(setting.module.code);
            }
          }
        })
    },
    showData(result){
      this.model = result
      this.items = result.details
      if(result.tracks){
        this.fieldsTimeLine = []
        for(let i=0; i<result.tracks.length; i++){
          this.fieldsTimeLine.push({
            title:result.tracks[i]['description'],
            description:'Diproses pada '+moment(result.tracks[i]['created_at']).format('DD MMMM YYYY HH:mm:ss')
          })
        }
        this.fieldsTimeLine.is_reverse = true
      }
    },
    action(action){
      const id = this.$route.params.id
      
      this.$bvModal
        .msgBoxConfirm('Are you sure ?', {
          title: 'Please Confirm',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
          okVariant:'info',
          okTitle: 'Yes',
          cancelTitle: 'No',
        })
        .then(confirm => {
          if(confirm){
            this.$http.put(this.dataurl+'/'+id+'/'+action)
              .then(() => {
                this.$refs.baseinfo.fetchData()
            })
          }
        })
    }
  }
}
</script>
